import React, { lazy } from "react";
import { Navigate } from "react-router-dom";

/****Layouts*****/
const FullLayout = lazy(() => import("../layouts/full-layout/FullLayout.js"));
const BlankLayout = lazy(() =>
  import("../layouts/blank-layout/BlankLayout.js")
);
const AdminLayout = lazy(() =>
  import("../layouts/admin-layout/AdminLayout.js")
);
/****End Layouts*****/

const Error = lazy(() => import("../views/authentication/Error"));
const Login = lazy(() => import("../views/authentication/Login"));
const Register = lazy(() => import("../views/authentication/Register"));
const ResetPassword = lazy(() =>
  import("../views/authentication/ResetPassword")
);

/*****Pages******/

const Dashboard2 = lazy(() => import("../views/dashboards/Dashboard2"));

const Slopes = lazy(() => import("../views/apps/slope/Slopes.js"));
/****BILLS****/
const Bill = lazy(() => import("../views/apps/bill/BillGrid"));
const NewBill = lazy(() => import("../views/apps/bill/NewBill"));
const EditBill = lazy(() => import("../views/apps/bill/EditBill"));

const NewComplementBill = lazy(() =>
  import("../views/apps/bill/complement-bill/NewComplementBill")
);
const CustomerLists = lazy(() =>
  import("../views/apps/customers/CustomerLists")
);
const Products = lazy(() =>
  import("../views/apps/ProductsAndServices/ProductsAndServices")
);

const ExpensesPage = lazy(() =>
  import("../views/apps/Expenses/ExpensesPage.js")
);
//Contabilidad
const TaxStatusDashboard = lazy(() =>
  import("../views/apps/TaxStatusDashboard/TaxStatusDashboard")
);
const Declarations = lazy(() =>
  import("../views/apps/TaxStatusDashboard/Declarations/Declarations.js")
);
const Compliances = lazy(() =>
  import("../views/apps/TaxStatusDashboard/Compliances/Compliances.js")
);

const AccountingReports = lazy(() =>
  import("../views/apps/AccountingReports/AccountingReports")
);

const StatementOfIncome = lazy(() =>
  import("../views/apps/AccountingReports/StatementOfIncome")
);

const TaxSummary = lazy(() =>
  import("../views/apps/AccountingReports/TaxSummary")
);

//CFDI
const CfdiDownload = lazy(() =>
  import("../views/apps/CfdiDownload/CfdiDownload")
);

/****Store****/
const StoreSubscription = lazy(() =>
  import("../views/apps/Store/ShopSubscription")
);
const StoreBells = lazy(() => import("../views/apps/Store/ShopBells"));
const Store = lazy(() => import("../views/apps/Store/StorePoducts"));
const StorePay = lazy(() => import("../views/apps/Store/StorePay"));
const StoreServices = lazy(() =>
  import("../views/apps/Store/ShopAdditionalServices")
);

// userprofile
const UserProfile = lazy(() => import("../views/user-profile/UserProfile"));

//userOrders
const Orders = lazy(() => import("../views/Orders/Orders"));
//FAQ

const FrequentQuestionsPage = lazy(() =>
  import("../views/apps/FrequentQuestions/FrequentQuestionsPage")
);
/*****Routes******/

/*****Admin Routes******/

//userOrders
const DashboardPage = lazy(() =>
  import("../views/admin/Dashboard/DashboardPage")
);
//Clients page
const ClientsPage = lazy(() => import("../views/admin/Clients/ClientsPage"));
const ClientEditPage = lazy(() =>
  import("../views/admin/Clients/ClientEditPage")
);

//collection page
const CollectionPage = lazy(() =>
  import("../views/admin/Collection/CollectionPage")
);

//Statement routes
const StatementPage = lazy(() =>
  import("../views/admin/Statement/StatementPage.js")
);
const StatementClientPage = lazy(() =>
  import("../views/admin/Statement/StatementClientPage.js")
);

// Cfdi routes

const CfdiDownloadClientPage = lazy(() =>
  import("../views/admin/CfdiDownload/CfdiDownloadClientPage.js")
);

const HistoryCfdiDownloadClientPage = lazy(() =>
  import("../views/admin/CfdiDownload/HistoryCfdiDownloadClientPage.js")
);
const HistoryCfdiRelationsClientPage = lazy(() =>
  import("../views/admin/CfdiDownload/HistoryCfdiRelationsClientPage.js")
);
//Taxes
const TaxesPage = lazy(() => import("../views/admin/Taxes/TaxesPage.js"));
const TaxesClientPage = lazy(() =>
  import("../views/admin/Taxes/TaxesClientPage.js")
);

const SelectRegimenPage = lazy(() =>
  import("../views/admin/Taxes/SelectRegimenPage.js")
);

const LeaseIncomeLoadPage = lazy(() =>
  import("../views/admin/Taxes/LeaseIncomeLoad/LeaseIncomeLoadPage.js")
);
const ExpensesClientPage = lazy(() =>
  import("../views/admin/Taxes/Expenses/ExpensesClientPage.js")
);
const IncomeClientPage = lazy(() =>
  import("../views/admin/Taxes/Income/IncomeClientPage.js")
);
const ConfigWithholdingPage = lazy(() =>
  import("../views/admin/Taxes/LeaseIncomeLoad/ConfigWithholdingPage.js")
);
const IsrToPayPage = lazy(() =>
  import("../views/admin/Taxes/ProvisionalPayments/IsrToPayPage.js")
);
const DataTablePage = lazy(() =>
  import("../views/admin/Taxes/DataTable/DataTablePage.js")
);

const ProvisionalPaymentsPage = lazy(() =>
  import("../views/admin/Taxes/ProvisionalPayments/ProvisionalPaymentsPage.js")
);
const IvaPage = lazy(() => import("../views/admin/Taxes/Iva/IvaPage.js"));

const PaymentSummaryPage = lazy(() =>
  import("../views/admin/Taxes/PaymentSummary/PaymentSummaryPage.js")
);

const RelatePage = lazy(() =>
  import("../views/admin/Taxes/Relate/RelatePage.js")
);

const TaxSummaryPage = lazy(() =>
  import("../views/admin/Taxes/TaxSummary/TaxSummaryPage.js")
);

const AccumulatedLoadPage = lazy(() =>
  import("../views/admin/Taxes/AccumulatedLoad/AccumulatedLoadPage.js")
);

//Config page
const ConfigPage = lazy(() => import("../views/admin/Config/ConfigPage.js"));

const BreakdownPage = lazy(() =>
  import("../views/admin/Taxes/Breakdown/BreakdownPage.js")
);

//Reports Page
const ReportsPage = lazy(() => import("../views/admin/Reports/ReportsPage.js"));

/*****Admin Routes******/

const ThemeRoutes = (isLoggedIn, isUser, isAdmin, isSeller, isAccountant) => [
  {
    path: "/",
    element: isLoggedIn ? <FullLayout /> : <Navigate to="/auth/login" />,
    children: [
      {
        path: "/",
        element: isUser ? <Navigate to="home" /> : <Navigate to="admin" />,
      },
      { path: "home", exact: true, element: <Dashboard2 /> },
      { path: "customers", exact: true, element: <CustomerLists /> },
      { path: "slopes", element: <Slopes /> },
      { path: "products-services", element: <Products /> },
      { path: "bills", element: <Bill /> },
      { path: "nueva-factura", element: <NewBill /> },
      { path: "nueva-factura-complemento", element: <NewComplementBill /> },
      { path: "/editar-factura/:id", element: <EditBill /> },
      { path: "/expenses", element: <ExpensesPage /> },
      { path: "/tax-status-dashboard", element: <TaxStatusDashboard /> },
      { path: "/statements", element: <Declarations /> },
      { path: "/compliance-opinions", element: <Compliances /> },
      { path: "/accounting-reports", element: <AccountingReports /> },
      { path: "/statement-of-income", element: <StatementOfIncome /> },
      { path: "/tax-summary", element: <TaxSummary /> },
      { path: "/descargas-cfdi", element: <CfdiDownload /> },
      { path: "/tienda-servicios", element: <StoreServices /> },
      { path: "/tienda-subscripciones", element: <StoreSubscription /> },
      { path: "/tienda-timbres", element: <StoreBells /> },
      { path: "/tienda", element: <Store /> },
      { path: "/frequent-questions", element: <FrequentQuestionsPage /> },
      { path: "/pago", element: <StorePay /> },
      { path: "/user-profile", element: <UserProfile /> },
      { path: "/user-orders", element: <Orders /> },
      { path: "*", element: <Navigate to="/auth/404" /> },
    ],
  },
  //rutas todo tipo de admin
  {
    path: "/admin",
    element: isLoggedIn && !isUser ? <AdminLayout /> : <Navigate to="/home" />,
    children: [
      { path: "/admin", element: <Navigate to="dashboard" /> },
      { path: "dashboard", exact: true, element: <DashboardPage /> },
      { path: "clients", exact: true, element: <ClientsPage /> },
      { path: "*", element: <Navigate to="/admin/dashboard" /> },
      // { path: "config", exact: true, element: <ConfigPage /> },
      // { path: "statement", element: <StatementPage /> },
      // { path: "collection", element: <CollectionPage /> }
    ],
  },
//rutas solo admin - admin
  {
    path: "/admin",
    element:
      isLoggedIn && isAdmin ? (
        <AdminLayout />
      ) : (
        <Navigate to="/admin/dashboard" />
      ),
    children: [
      { path: "config", element: <ConfigPage /> },
      { path: "collection", element: <CollectionPage /> },
      { path: "reports", element: <ReportsPage /> },
    ],
  },
//rutas solo admin - contador
  {
    path: "/admin",
    element:
      isLoggedIn && (isAdmin || isAccountant) ? (
        <AdminLayout />
      ) : (
        <Navigate to="/admin/dashboard" />
      ),
    children: [
      { path: "client-edit", exact: true, element: <ClientEditPage /> },
      { path: "statement", element: <StatementPage /> },
      { path: "statement-client", element: <StatementClientPage /> },
      { path: "taxes", element: <TaxesPage /> },
      { path: "select-regimen", element: <SelectRegimenPage /> },
      {
        path: "taxes-client",
        element: <TaxesClientPage />,
        children: [
          { path: "lease-income-load", element: <LeaseIncomeLoadPage /> },
          { path: "income", element: <IncomeClientPage /> },
          { path: "config-withholding", element: <ConfigWithholdingPage /> },
          { path: "expenses", element: <ExpensesClientPage /> },
          { path: "data-table", element: <DataTablePage /> },
          { path: "isr-to-pay", element: <IsrToPayPage /> },
          {
            path: "provisional-payments",
            element: <ProvisionalPaymentsPage />,
          },
          {
            path: "breakdown",
            element: <BreakdownPage />,
          },
          { path: "iva", element: <IvaPage /> },
          { path: "payment-summary", element: <PaymentSummaryPage /> },
          { path: "relate", element: <RelatePage /> },
        ],
      },
     
      { path: "cfdi-download-client", element: <CfdiDownloadClientPage /> },
      { path: "tax-summary-client", element: <TaxSummaryPage /> },
      { path: "accumulated-load-client", element: <AccumulatedLoadPage /> },
      {
        path: "history-download-cfdi-client",
        element: <HistoryCfdiDownloadClientPage />,
      },
      {
        path: "history-cfdi-relations-client",
        element: <HistoryCfdiRelationsClientPage />,
      },
    ],
  },
  //rutas solo admin - vendedor
  // {
  //   path: "/admin",
  //   element:
  //     isLoggedIn && (isAdmin || isSeller) ? (
  //       <AdminLayout />
  //     ) : (
  //       <Navigate to="/admin/dashboard" />
  //     ),
  //   children: [{ path: "collection", element: <CollectionPage /> }],
  // },

  //rutas publicas
  {
    path: "/auth",
    element: <BlankLayout />,
    children: [
      { path: "auth", element: <Navigate to="/login" /> },
      { path: "404", element: <Error /> },
      { path: "login", element: <Login /> },
      { path: "register", element: <Register /> },
      { path: "reset-password", element: <ResetPassword /> },
      { path: "*", element: <Navigate to="/auth/404" /> },
    ],
  },
];

export default ThemeRoutes;
