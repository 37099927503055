const { MIX_JWT_TOKEN } = process.env;
const { MIX_API_OPINIONS_MICROSERVICE_URL } = process.env;
const { MIX_PUSHER_APP_KEY } = process.env;
const { REACT_APP_API_URL } = process.env;
const { REACT_APP_CONEKTA_PUBLIC_KEY } = process.env;
const { REACT_APP_CFDI_API_URL } = process.env;
const { REACT_APP_STRIPE_KEY_TEST } = process.env;
const { REACT_APP_STRIPE_SECRET_TEST } = process.env;
const { REACT_APP_APP_URL } = process.env;

export const API_HOST = REACT_APP_API_URL;
export const APP_HOST = REACT_APP_APP_URL;
export const API_CFDI = REACT_APP_CFDI_API_URL;
export const TOKEN = MIX_JWT_TOKEN;
export const CONEKTA_KEY = REACT_APP_CONEKTA_PUBLIC_KEY;
export const API_MICROSERVICE_OPINION_HOST = MIX_API_OPINIONS_MICROSERVICE_URL;
export const PUSHER_KEY = MIX_PUSHER_APP_KEY;
export const KEY_TEST = REACT_APP_STRIPE_KEY_TEST;
export const CLIENT_SECRET = REACT_APP_STRIPE_SECRET_TEST;
