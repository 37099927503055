import React, { useEffect, useState } from "react";
import { useRoutes } from "react-router-dom";
import RTL from "./layouts/full-layout/customizer/RTL";
import { CssBaseline, ThemeProvider } from "@material-ui/core";
import ThemeSettings from "./layouts/full-layout/customizer/settings";
import { useSelector } from "react-redux";
import Themeroutes from "./routes/Router";
import "react-perfect-scrollbar/dist/css/styles.css";
import SetInterceptors from "./api/axiosInterceptors";
// import { isUserLogedApi } from "./api/auth";
import { Toaster } from "react-hot-toast";
// import { axiosMe } from './api/userInfo';
import "./views/authentication/Inputs.scss";
import { API_HOST } from "./utils/constant";

import { AuthContext } from "./utils/contexts";
import Spinner from "./views/spinner/Spinner";

/* 
Pagina principal que carga los datos del usuario,
 configuraciones iniciales y tema de toda la aplicación 
 como realiza el route de cada pagina 
 */

const App = () => {
  const theme = ThemeSettings();
  const customizer = useSelector((state) => state.CustomizerReducer);
  let isLogged = false;
  let isAdmin = false;
  let isAccountant = false;
  let isSeller = false;
  let isUser = false;
  const [user, setUser] = useState({ name: "user", roles_id: 0 });
  const [refreshCheckLogin, setRefreshCheckLogin] = useState(false);
  const [token, setToken] = useState(localStorage.getItem("token"));
  const [role, setRole] = useState(localStorage.getItem("role"));

  token == null && user.name === "user" && role == null
    ? (isLogged = false)
    : (isLogged = true);

  token != null && Number(role) === 1 ? (isAdmin = true) : (isAdmin = false);

  token != null && Number(role) === 2 ? (isUser = true) : (isUser = false);

  token != null && Number(role) === 3
    ? (isAccountant = true)
    : (isAccountant = false);

  token != null && Number(role) === 4 ? (isSeller = true) : (isSeller = false);

  const routing = useRoutes(
    Themeroutes(isLogged, isUser, isAdmin, isSeller, isAccountant)
  );

  //función para cargar los datos adicionales del usuario después de loggear 
  const getUser = async (jwt) => {
    let url = `${API_HOST}/api/me`;
    const response = await fetch(url, {
      method: "POST",
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    });

    const tmpUser = await response.json();
    setUser(tmpUser);

    localStorage.setItem("role", tmpUser?.roles_id);
    setRole(tmpUser?.roles_id);
  };

  //limpieza de usuario si no existe token
  useEffect(() => {
    if (token) {
      getUser(token);
    } else {
      setUser({ name: "user", roles_id: 0 });
    }
  }, [token]);

  useEffect(() => {
    if (token) {
      SetInterceptors();
      setRefreshCheckLogin(true);
    } else {
      setRefreshCheckLogin(refreshCheckLogin);
    }
  }, [refreshCheckLogin]);

  return (
    <AuthContext.Provider value={{ user, setUser, token, setToken, setRole }}>
      <ThemeProvider theme={theme}>
        <RTL direction={customizer.activeDir}>
          <CssBaseline />
          {user ? routing : <Spinner />}
        </RTL>
        <Toaster
          position="top-center"
          reverseOrder={true}
          gutter={8}
          containerClassName=""
          containerStyle={{}}
          toastOptions={{
            // Define default options
            className: "",
            duration: 6000,
            style: {
              background: "#021E4C",
              color: "#fff",
            },
            // Default options for specific types
            success: {
              duration: 4000,
              theme: {
                primary: "green",
                secondary: "black",
              },
            },
          }}
        />
      </ThemeProvider>
    </AuthContext.Provider>
  );
};

export default App;
