import { combineReducers } from "redux";
import CustomizerReducer from "./customizer/Reducer";
import notesReducer from "./notes/Reducer";
import AuthReducer from './autentication/AuthReducer';

import AuthenticationReducer from './autentication/AuthenticationReducer';
import { users } from './autentication/UsersReducer';

const RootReducers = combineReducers({
  CustomizerReducer,
  // chatReducer,
  notesReducer,
  // emailReducer,
  userAuth: AuthReducer,
  // userDetails: ProfileReducer,
  AuthenticationReducer,
  users,
});

export default RootReducers;