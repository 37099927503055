import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { store } from "./redux/Store";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import "./data";
import reportWebVitals from './reportWebVitals';
import Spinner from "./views/spinner/Spinner";

/* Se renderiza el proveedor redux para que se usa para el Login, como el Suspense que espera la carga de las rutas
y BrowserRouter que envuelve todas las rutas */

ReactDOM.render(
  <Provider store={store}>
    <Suspense fallback={<Spinner />}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Suspense>
  </Provider>,
  document.getElementById("root")
);

reportWebVitals ();