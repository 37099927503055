import { API_HOST } from "../utils/constant";
import jwt_decode from "jwt-decode";
import axios from "axios";

/*
 Peticiones axios que se utilizan para el login,token, registro y logout 
*/

export function signUpApi(user) {
  const url = `${API_HOST}/api/register-basic-data`;
  const userTemp = {
    ...user,
    email: user?.email.toLowerCase(),
    country_code: user?.lada,
  };
  delete userTemp.lada;

  const params = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("token"),
    },
    body: JSON.stringify(userTemp),
  };

  return fetch(url, params)
    .then((response) => {
      if (response.status >= 200 && response.status < 300) {
        return response.json();
      } else if (response.status === 404 || response.status === 422) {
        return { code: 404, message: "Email no disponible" };
      } else {
        return { code: 500, message: "Error en el formato de los datos" };
      }
    })
    .then((result) => {
      return result;
    })
    .catch((err) => {
      return err;
    });
}

export function refreshAccessToken() {
  const url = `${API_HOST}/api/auth/refresh`;
  const params = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json, text-plain, */*",
      "X-Requested-With": "XMLHttpRequest",
      Authorization: getTokenApi(),
    },
  };

  return fetch(url, params)
    .then((response) => {
      if (response.status >= 200 && response.status < 300) {
        return response.json();
      }
    })
    .then((result) => {
      return result;
    })
    .catch((err) => {
      return err;
    });
}

export function signInApi(user) {
  const url = `${API_HOST}/api/auth/login`;
  const data = {
    ...user,
    email: user.email.toLowerCase(),
  };

  const params = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json, text-plain, */*",
      "X-Requested-With": "XMLHttpRequest",
    },
    body: JSON.stringify({
      email: data.email,
      password: data.password,
    }),
  };

  return fetch(url, params)
    .then((response) => {
      if (response.status >= 200 && response.status < 300) {
        return response.json();
      }
      return { message: "Usuario o contraseña incorrectos" };
    })
    .then((result) => {
      return result;
    })
    .catch((err) => {
      return err;
    });
}

export function setTokenApi(token) {
  localStorage.setItem("token", token);
  if (
    localStorage.getItem("token") != null ||
    localStorage.getItem("token").length > 0
  ) {
    return true;
  }

  return false;
}

export function getTokenApi() {
  let jwt = localStorage.getItem("token");
  return jwt;
}

export function firstTimeInApp() {
  return localStorage.getItem("firstTime");
}

export const logoutApi = async () => {
  const url = `${API_HOST}/api/logout`;
  const jwt = localStorage.getItem("token");

  const params = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: jwt,
    },
    body: JSON.stringify({
      token: jwt,
    }),
  };

  let response = await fetch(url, params)
    .then((response) => {
      if (response.status >= 200 && response.status < 300) {
        localStorage.removeItem("token");
        localStorage.removeItem("image");
        localStorage.removeItem("firstTime");
        localStorage.removeItem("role");
        return response;
      }
      if (response.status === 500) {
        localStorage.removeItem("token");
        localStorage.removeItem("image");
        localStorage.removeItem("role");
        localStorage.removeItem("firstTime");
      }

      return { status: 404, message: "No se ha podido remover el token" };
    })
    .then((result) => {
      return result;
    })
    .catch((err) => {
      return err;
    });

  return response;
};

export function isUserLogedApi() {
  const token = getTokenApi();
  if (!token) {
    logoutApi();
    return null;
  }

  // Is variable function
  if (isExpired(token)) {
    // logoutApi();
    // refreshAccessToken();
    // SetInterceptors();
  }

  return jwt_decode(token);
}

function isExpired(token) {
  const { exp } = jwt_decode(token);
  const expire = exp * 1000;
  const timeout = expire - Date.now();

  if (timeout < 30) {
    return true;
  }
  return false;
}

export const forgotPassword = async (email) => {
  const url = `${API_HOST}/api/forgot-password`;
  return await axios
    .post(url, { email })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err;
    });
};
