import { TOKEN } from "../utils/constant";
import { getTokenApi, refreshAccessToken } from "./auth";
import axios from "axios";
/*
 Interceptores del login para manejo del token y logo de la cuenta
*/
const SetInterceptors = () => {
  axios.interceptors.request.use(
    async (config) => {
      config.headers = {
        Authorization: getTokenApi(),
      };

      return config;
    },
    (err) => {
      Promise.reject(err);
    }
  );

  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response.status !== 401) {
        return new Promise((resolve, reject) => {
          reject(error);
        });
      }

      if (
        error.response.data === "Unauthenticated." ||
        error.response.status === 401
      ) {
        localStorage.removeItem(TOKEN);
        localStorage.removeItem("image");
        localStorage.removeItem("role");

        return new Promise((resolve, reject) => {
          reject(error);
        });
      }

      return refreshAccessToken()
        .then((token) => {
          const config = error.config;

          config.heades["Authorization"] = `Bearer ${token}`;

          return new Promise((resolve, reject) => {
            axios
              .request(config)
              .then((response) => {
                resolve(response);
              })
              .catch((err) => reject(err));
          });
        })
        .catch((err) => {
          Promise.reject(error);
        });
    }
  );
};

export default SetInterceptors;
