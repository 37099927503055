import * as ActionTypes from '../ActionTypes';

let user = JSON.parse(localStorage.getItem('user'));
    
const INIT_STATE = {
    authResponse: "",
    user: user ?  {loggedIn: true, user } : {},
};

const AuthReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
    case ActionTypes.RESTART_AUTH_RESPONSE:
    return {
        ...state,
        authResponse: "",
        loggedIn: false,
    };

    case ActionTypes.LOADING:
    return {
        ...state,
        authResponse: "loading...",
    };

    case ActionTypes.SIGNUP_SUCCESS:
    return {
        ...state,
        authResponse: action.res,
    };

    case ActionTypes.SIGNUP_ERROR:
    return {
        ...state,
        authResponse: action.res,
    };

    case ActionTypes.LOGIN_SUCCESS:
    return {
       loggedIn: true,
        authResponse: "redirecting to dashboard...",
        user: action.user
    };
    
    case ActionTypes.LOGIN_ERROR:
    return {
        ...state,
        loggedIn: false,
        authResponse: action.res,
    }; 

    case ActionTypes.LOGOUT_SUCCESS:
    return {
        ...state,
        authResponse: action.res,
    };

    case ActionTypes.LOGOUT_ERROR:
    return {
        ...state,
        authResponse: action.res,
    }; 

    case ActionTypes.CODE_ERROR:
    return {
        ...state,
        authResponse:
        "Al parecer tenemos un problema con el servidor, favor de reiniciar su navegador.",
    };

    default:

    return state;
    }
};

export default AuthReducer;