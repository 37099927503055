
import { createStore, applyMiddleware } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { createLogger } from 'redux-logger';
import RootReducers from "./Rootreducers";

const loggerMiddleware = createLogger();

export const store = createStore(
  RootReducers,
  applyMiddleware(
    thunkMiddleware,
    loggerMiddleware
  )
);